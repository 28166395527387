import debug from 'debug';
import {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Dropdown, type DropdownItemProps, List, ListItem, Loader} from 'semantic-ui-react';
import {getFullName} from '~lib/getFullName';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {useReemployCorporateEmployeeMutation} from '../EndEmploymentForm/reemployCorporateEmployee';
import {useGetEmployeeAddSidebarPreviousEmployeesQuery} from './getEmployeeAddSidebarPreviousEmployees';

const d = debug('tacs.web.accounts.components.employees.EmployeeAddSidebarPreviousEmployeeList');

export function EmployeeAddSidebarPreviousEmployeeList() {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {push} = useHistory();
	const {accountInfoId} = useParams<typeof routes.types.employees>();
	const [contactId, setContactId] = useState<string | undefined>();

	const {data, loading, error} = useGetEmployeeAddSidebarPreviousEmployeesQuery({variables: {accountInfoId}});
	const [reemployCorporateEmployeeMutation, {loading: mutationLoading}] = useReemployCorporateEmployeeMutation();

	if (loading) return <Loader active />;
	if (error) throw error;
	if (!data) throw new Error("The data couldn't be loaded");

	const employees = data.getEmployeesByAccountInfoId;
	const options: DropdownItemProps[] =
		employees?.map(e => {
			return {
				key: e.id,
				value: e.id,
				text: getFullName(e.contact),
			};
		}) || [];

	const onReEmployClick = (employeeId: string) => {
		const employee = data?.getEmployeesByAccountInfoId?.find(em => em.id === employeeId);
		if (employee) {
			reemployCorporateEmployeeMutation({
				variables: {employee: {id: employee.id, version: employee.version}},
			})
				.then(() => push(routes.to.viewEmployee({accountInfoId, employeeId})))
				.catch(throwError);
		}
	};

	return (
		<List>
			<List.Header as="h3">Re-employ Previous Employees</List.Header>
			<ListItem>
				<Dropdown
					search
					fluid
					selection
					clearable
					placeholder="Select employee..."
					noResultsMessage="No matches..."
					options={options}
					value={contactId}
					onChange={(e, val) => typeof val?.value === 'string' && setContactId(val?.value)}
				/>
			</ListItem>
			{contactId && (
				<ListItem>
					<Button disabled={mutationLoading || locked || !contactId} floated="right" positive onClick={() => onReEmployClick(contactId)}>
						Save
					</Button>
					<Button type="button" floated="right" onClick={() => setContactId(undefined)}>
						Cancel
					</Button>
				</ListItem>
			)}
		</List>
	);
}
