import {useAuthenticatedState} from '@imperium/auth-client';
import debug from 'debug';
import {useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import {Button, Grid, GridColumn, GridRow, Icon, Modal, Placeholder} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {routes as userRoutes} from '../../../../user/routes';
import {useGetPasswordResetLinkQuery} from './getPasswordResetLink';
import {useSendContactPasswordResetEmailMutation} from './sendContactPasswordResetEmail';

const d = debug('tacs.web.accounts.components.contacts.SendPasswordResetEmailButton');

type Props = {
	email: string | undefined;
	userId: string;
};

export function SendPasswordResetEmailButton({email, userId}: Props) {
	const [open, setOpen] = useState(false);
	const throwError = useAsyncError();
	const {id} = useAuthenticatedState();
	const [sendPasswordResetEmail] = useSendContactPasswordResetEmailMutation();
	const {
		data: invitationData,
		error: invitationError,
		loading: invitationLoading,
	} = useGetPasswordResetLinkQuery({
		variables: {userId, passwordResetPageEndpoint: userRoutes.to.passwordReset(), hostname: window.location.origin.toString()},
	});

	if (invitationLoading)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	if (invitationError) throw invitationError;
	if (!invitationData) throw new Error("Data couldn't be loaded");

	const sendResetEmail = async () => {
		if (!id) throw new Error('You must be logged in to send a Reset Password Email.');

		if (email) {
			sendPasswordResetEmail({
				variables: {
					email,
					hostname: window.location.origin.toString(),
					passwordResetEndpoint: userRoutes.to.passwordReset(),
				},
			})
				.then(res => {
					if (res.data?.sendPasswordResetEmail) {
						toast.success(`Success! A reset email has been sent to ${email}.`);
					} else {
						throwError(new Error('Password reset failed.'));
					}
				})
				.catch(throwError);
		}
	};
	return (
		<Modal
			open={open}
			size="small"
			dimmer
			trigger={
				<Button fluid color="green" onClick={() => setOpen(true)} disabled={!email}>
					Send Password Reset Email
				</Button>
			}
		>
			<Modal.Content>
				<Grid columns="2">
					<GridRow>
						<GridColumn>
							<h3>
								<Icon color="green" name="user" />
								Send Password Reset Email
							</h3>
						</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn width={16}>
							Send a password reset request by automatically sending the email, or manually copying it to your clipboard.
						</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn width={16} style={{wordWrap: 'break-word'}}>
							<code>{invitationData?.getPasswordResetLink}</code>
						</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn width={10}>
							<Button
								color="green"
								onClick={() => {
									setOpen(false);
									sendResetEmail().catch(e => throwError(e));
								}}
							>
								<Icon name="mail" /> Send Reset Email
							</Button>
							<CopyToClipboard
								text={invitationData.getPasswordResetLink}
								onCopy={() => {
									toast.success('Registration link copied to clipboard');
								}}
							>
								<Button
									color="blue"
									onClick={() => {
										setOpen(false);
									}}
								>
									<Icon name="clipboard" /> Copy URL to clipboard
								</Button>
							</CopyToClipboard>
						</GridColumn>
						<GridColumn textAlign="right" width={6}>
							<Button
								color="orange"
								floated="right"
								onClick={() => {
									setOpen(false);
								}}
							>
								<Icon name="dont" /> Cancel
							</Button>
						</GridColumn>
					</GridRow>
				</Grid>
			</Modal.Content>
		</Modal>
	);
}
