import {useCan} from '@imperium/auth-client';
import type {LayoutData} from '@imperium/layout';
import {useLayoutState} from '@imperium/layout';
import debug from 'debug';
import {dateRangeSelectionHook} from '~common/hooks/dateRangeSelection/dateRangeSelectionHook';
import {DateRangeSelection, DefaultDateSetting} from '~common/types';
import {Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {routes as accountRoutes, routes} from '../accounts/routes';
import {useAccountState} from '../accounts/state';
import {UserMenuItem} from './components/UserMenuItem';
import {UserNotificationMenuItem} from './components/UserNotificationMenuItem';
import {useUserInfo} from './hooks/useUserInfo';
import {useUserState} from './state';

const d = debug('tacs.web.user.layout');

export const layout: LayoutData = {
	permissions: [Permission.ManageAllAgents, Permission.ViewAdminPayrollAccount],
	dataHooks: [
		useUserInfo,
		dateRangeSelectionHook({
			routeMatch: routes.match.timeline,
			selection: DateRangeSelection.DateRange,
			defaultDateSetting: DefaultDateSetting.TodayFy,
		}),
	],
	primaryMenu: [
		{
			stateSelectorHook: [useUserState],
			stickOnMobile: true,
			render: UserNotificationMenuItem,
			position: 'right',
			visible: isLoggedIn(),
		},
		{
			stateSelectorHook: [useUserState],
			stickOnMobile: true,
			render: UserMenuItem,
			position: 'right',
			visible: isLoggedIn(),
		},
		{
			text: 'Register',
			visible: {...isLoggedIn(), ...needsPermission(Permission.ReadAllAccount)},
			to: accountRoutes.to.createAccount(),
			icon: 'address book',
		},
	],
	secondaryMenu: [
		{
			text: 'Timeline',
			icon: 'file',
			stateSelectorHook: [useAccountState, useLayoutState],
			visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
			to: data => (data.state.params?.accountInfoId ? routes.to.timeline({accountInfoId: data.state.params.accountInfoId}) : ''),
			moveToKey: 'settings',
			permissionSelectorHook: [
				data => {
					const [viewAdminPayrollAccount] = useCan([
						{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
					]);
					return {viewAdminPayrollAccount};
				},
			],
		},
		{
			text: 'Account',
			visible: {...isLoggedIn(), ...needsRoute('register'), ...needsPermission(Permission.ReadAllAccount)},
			to: accountRoutes.to.createAccount(),
		},
		{
			text: 'Lead',
			visible: {...isLoggedIn(), ...needsRoute('register'), ...needsPermission(Permission.ReadAllAccount)},
			to: accountRoutes.to.createLead(),
		},
	],
};
