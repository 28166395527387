import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getSourceDeductionTableData.graphql';

const defaultOptions = {} as const;
export type GetSourceDeductionTableDataQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	year: Types.Scalars['Int'];
}>;

export type GetSourceDeductionTableDataQuery = {
	getSourceDeductionByAccountInfoAndYear?: {
		openingBalance?: {id: string; version: number; amount: Money; description?: string | null; year: number} | null;
		rows: Array<
			| {
					id: string;
					version: number;
					date: LocalDate;
					period: LocalDate;
					type: Types.SourceDeductionRowType;
					cpp?: Money | null;
					cpp2?: Money | null;
					ei?: Money | null;
					grossIncome?: Money | null;
					remittance?: Money | null;
					tax?: Money | null;
					payStubName?: string | null;
					otherDeductions?: Array<{id: string; amount: Money}> | null;
					reimbursements?: Array<{id: string; amount: Money}> | null;
			  }
			| {
					id: string;
					version: number;
					date: LocalDate;
					period: LocalDate;
					type: Types.SourceDeductionRowType;
					amount: Money;
					description?: string | null;
			  }
			| {
					id: string;
					version: number;
					date: LocalDate;
					period: LocalDate;
					type: Types.SourceDeductionRowType;
					nsf?: boolean | null;
					nsfDate?: LocalDate | null;
					chequeNumber?: number | null;
					lineItems?: Array<{id: string; amount: Money; arrears: boolean; description: string}> | null;
			  }
		>;
	} | null;
	getSourceDeductionsStatementBalanceByAccountInfoAndYear?: Array<{id: string; amount: Money; period: LocalDate}> | null;
};

export type GetSourceDeductionTableDataType = {
	openingBalance?: {id: string; version: number; amount: Money; description?: string | null; year: number} | null;
	rows: Array<
		| {
				id: string;
				version: number;
				date: LocalDate;
				period: LocalDate;
				type: Types.SourceDeductionRowType;
				cpp?: Money | null;
				cpp2?: Money | null;
				ei?: Money | null;
				grossIncome?: Money | null;
				remittance?: Money | null;
				tax?: Money | null;
				payStubName?: string | null;
				otherDeductions?: Array<{id: string; amount: Money}> | null;
				reimbursements?: Array<{id: string; amount: Money}> | null;
		  }
		| {
				id: string;
				version: number;
				date: LocalDate;
				period: LocalDate;
				type: Types.SourceDeductionRowType;
				amount: Money;
				description?: string | null;
		  }
		| {
				id: string;
				version: number;
				date: LocalDate;
				period: LocalDate;
				type: Types.SourceDeductionRowType;
				nsf?: boolean | null;
				nsfDate?: LocalDate | null;
				chequeNumber?: number | null;
				lineItems?: Array<{id: string; amount: Money; arrears: boolean; description: string}> | null;
		  }
	>;
};

/**
 * __useGetSourceDeductionTableDataQuery__
 *
 * To run a query within a React component, call `useGetSourceDeductionTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceDeductionTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceDeductionTableDataQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetSourceDeductionTableDataQuery(
	baseOptions: Apollo.QueryHookOptions<GetSourceDeductionTableDataQuery, GetSourceDeductionTableDataQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetSourceDeductionTableDataQuery, GetSourceDeductionTableDataQueryVariables>(Operations, options);
}
export function useGetSourceDeductionTableDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetSourceDeductionTableDataQuery, GetSourceDeductionTableDataQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetSourceDeductionTableDataQuery, GetSourceDeductionTableDataQueryVariables>(Operations, options);
}
export type GetSourceDeductionTableDataQueryHookResult = ReturnType<typeof useGetSourceDeductionTableDataQuery>;
export type GetSourceDeductionTableDataLazyQueryHookResult = ReturnType<typeof useGetSourceDeductionTableDataLazyQuery>;
export type GetSourceDeductionTableDataQueryResult = Apollo.QueryResult<GetSourceDeductionTableDataQuery, GetSourceDeductionTableDataQueryVariables>;
