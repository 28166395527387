import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getPasswordResetLink.graphql';

const defaultOptions = {} as const;
export type GetPasswordResetLinkQueryVariables = Types.Exact<{
	passwordResetPageEndpoint: Types.Scalars['String'];
	userId: Types.Scalars['String'];
	hostname: Types.Scalars['String'];
}>;

export type GetPasswordResetLinkQuery = {getPasswordResetLink: string};

export type GetPasswordResetLinkType = string;

/**
 * __useGetPasswordResetLinkQuery__
 *
 * To run a query within a React component, call `useGetPasswordResetLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPasswordResetLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPasswordResetLinkQuery({
 *   variables: {
 *      passwordResetPageEndpoint: // value for 'passwordResetPageEndpoint'
 *      userId: // value for 'userId'
 *      hostname: // value for 'hostname'
 *   },
 * });
 */
export function useGetPasswordResetLinkQuery(baseOptions: Apollo.QueryHookOptions<GetPasswordResetLinkQuery, GetPasswordResetLinkQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetPasswordResetLinkQuery, GetPasswordResetLinkQueryVariables>(Operations, options);
}
export function useGetPasswordResetLinkLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetPasswordResetLinkQuery, GetPasswordResetLinkQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetPasswordResetLinkQuery, GetPasswordResetLinkQueryVariables>(Operations, options);
}
export type GetPasswordResetLinkQueryHookResult = ReturnType<typeof useGetPasswordResetLinkQuery>;
export type GetPasswordResetLinkLazyQueryHookResult = ReturnType<typeof useGetPasswordResetLinkLazyQuery>;
export type GetPasswordResetLinkQueryResult = Apollo.QueryResult<GetPasswordResetLinkQuery, GetPasswordResetLinkQueryVariables>;
