import {InMemoryCache} from '@apollo/client';
import {authClientModule, authorizationEndpointLookup} from '@imperium/auth-client';
import {authGraphqlClientModule} from '@imperium/auth-graphql-client';
import type {ImperiumClientModule} from '@imperium/client';
import {graphqlClientModule} from '@imperium/graphql-client';
import {layoutClientModule} from '@imperium/layout';
import {routerClientModule} from '@imperium/router';
import {stateClientModule} from '@imperium/state';
import {commonModule} from '~common/index';
import {scalarTypePolicies} from '~core/graphql';
import {accountingModule} from '../accounting';
import {accountsModule} from '../accounts';
import {administrationModule} from '../administration';
import {assetModule} from '../assets';
import {calendarModule} from '../calendar';
import {communicationsModule} from '../communications';
import {corporateBinderModule} from '../corporateBinder';
import {corporateTaxesModule} from '../corporateTaxes';
import {documentsModule} from '../documents';
import {gstModule} from '../gst';
import {payrollModule} from '../payroll';
import {personalTaxesModule} from '../personalTaxes';
import {ratesModule} from '../rates';
import {receivablesModule} from '../receivables';
import {reportsModule} from '../reports';
import {searchModule} from '../search';
import {supportModule} from '../support';
import {t5Module} from '../t5';
import {dashboardModule} from '../tasks';
import {toolsModule} from '../tools';
import {userModule} from '../user';

// order matters for layout menu
export function clientModules(): ImperiumClientModule[] {
	return [
		stateClientModule(),
		authClientModule({lookup: authorizationEndpointLookup}),
		authGraphqlClientModule(),
		routerClientModule(),
		graphqlClientModule({
			removeTypenameOnInput: true,
			apolloClientOptions: {
				cache: new InMemoryCache({
					typePolicies: scalarTypePolicies,
				}),
				defaultOptions: {watchQuery: {fetchPolicy: 'cache-and-network'}},
			},
		}),
		layoutClientModule(),
		dashboardModule(),
		accountsModule(),
		administrationModule(),
		assetModule(),
		communicationsModule(),
		commonModule(),
		corporateBinderModule(),
		personalTaxesModule(),
		corporateTaxesModule(),
		reportsModule(),
		gstModule(),
		t5Module(),
		accountingModule(),
		calendarModule(),
		documentsModule(),
		payrollModule(),
		ratesModule(),
		receivablesModule(),
		searchModule(),
		supportModule(),
		toolsModule(),
		userModule(),
	];
}
