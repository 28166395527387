import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, List, Loader} from 'semantic-ui-react';
import {routes} from '../../routes';
import {SendInvitationEmailButton} from '../contacts/SendInvitationEmailButton';
import {SendPasswordResetEmailButton} from '../contacts/SendPasswordResetEmailButton';
import {useGetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery} from './AuthorizedRepresentativeView/getAuthorizedRepresentativeViewAuthorizedRepresentative';
import {RemoveAuthorizedRepresentative} from './RemoveAuthorizedRepresentative';

const d = debug('tacs.web.accounts.components.authorizedRepresentatives.AuthorizedRepresentativeViewSidebar');

export function AuthorizedRepresentativeViewSidebar() {
	const {push} = useHistory();
	const {authorizedRepresentativeId, accountInfoId} = useParams<typeof routes.types.viewAuthorizedRepresentative>();

	const {data, loading, error} = useGetAuthorizedRepresentativeViewAuthorizedRepresentativeQuery({
		variables: {id: authorizedRepresentativeId},
	});

	if (loading) return <Loader active />;
	if (error) throw error;
	if (!data) throw new Error("The Data couldn't be loaded");

	return (
		<List>
			<List.Item>
				<SendInvitationEmailButton contactId={data.getAuthorizedRepresentativeById.contact.id} />
			</List.Item>
			<List.Item>
				{data.getAuthorizedRepresentativeById.contact.authorizedUser?.id && (
					<SendPasswordResetEmailButton
						email={data.getAuthorizedRepresentativeById.contact.authorizedUser?.email}
						userId={data.getAuthorizedRepresentativeById.contact.authorizedUser.id}
					/>
				)}
			</List.Item>
			<Divider />
			<List.Item>
				<Button fluid color="orange" onClick={() => push(routes.to.editAuthorizedRepresentative({accountInfoId, authorizedRepresentativeId}))}>
					Edit Authorized Representative
				</Button>
			</List.Item>
			<List.Item>
				<RemoveAuthorizedRepresentative authorizedRepresentativeId={authorizedRepresentativeId} />
			</List.Item>
		</List>
	);
}
